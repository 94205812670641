#root {
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  background-color: white;
}

body {
  background-color: white;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49,49,49,0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.header-table-page.h1 {
  font-size: 30px;
} 

.header-table-text {
  font-weight: 800;
}

.text-filters {
  text-align: left;
  font-size: 20px;
  color: black
}


.param-detail-page {
  margin-left: 15px;
  margin-top: 4em;
  margin-bottom: 4em;
  width: 70%;
}

.h1-param-detail {
  font-size: 2.8rem;
}

.param-detail-top-table {
  margin-top: 2em;
}


.param-detail-show-more-button {
  margin-left: 10em;
  margin-bottom: 10px;
}

.param-details-tabs {
  margin-top: 2em;
}

.param-details-origin-text {
  margin-left: 1em;
  margin-top: 2em;
}

.param-details-table-tab {
  margin-top: 1em;
  border-radius: 10px;
}

.param-detail-text-betw-tables-list {
  margin-top: 3.5em;
  margin-bottom: 3.5em;
}


.param-details-table-accordion {
  margin-bottom: 1em;
}

.filter-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}


.stack-chip-label{
  display: flex;
  align-items: center;
  flex-direction: row;
}

/* .stack-chip-label span{
  margin-right: 15px;
} */

.alert-encoding{
  margin-top: 32px;
}

.asteric-required{
  color: red;
}

.breadcrumb {
  margin-top: -1em;
  margin-bottom: 3em;
}

.not-found{
  margin-bottom: 5em;
}

.not-found-text {
  position: absolute;
}

/* class to fix the shadow of the parameter change link */
.linkEdit{
  width: 24px;
  height: 24px;
}

.legendText{
  font-size: 15px;
  margin: 20px !important;
  margin-bottom: 10px;
  padding-left: 20px;
}

.legendBox{
  display: inline;
}

.MuiTableContainer-root{
  border-radius: 17px !important;
}


.container-center {

}

.container-center > div {

}

.param-details-table-accordion .MuiTableCell-root {
  border-bottom: 1px solid  rgba(108, 117, 125, 0.15);
}

.param-details-table-tab .MuiTableCell-root {
  border-bottom: 1px solid  rgba(108, 117, 125, 0.15);
}

.param-detail-top-table .MuiTableCell-root {
  border-bottom: none;
}

.table-style .MuiTableCell-root {
  padding:12px 16px;
  border-bottom: none;
}

.tab{
  border-bottom: 1px solid rgb(228, 228, 228) !important;
  color: rgb(26, 118, 210) !important;
}

.Mui-selected{
  border: 1px solid rgb(228, 228, 228) !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border-bottom: none !important;
  color: black !important;
} 

.action-buttons .MuiIconButton-root{
  padding: 0px !important;
  margin-right: 5px;
}

.regex-switch span{
  font-size: 14px;
}

.css-15v22id-MuiAccordionDetails-root{
  padding: 0px !important;
  margin-top: 20px;
}

.buttonPrimary{
  box-shadow: none !important;
}

.paginator{
  display: flex;
  justify-content: end;
  margin-top: 70px;
  color: rgb(0, 0, 0) !important;
}

.showParametersNumber{
  display: flex;
  justify-content: start;
}

/* item selected from paginator */
.Mui-selected{
  color: white !important;
}


